import styled from 'styled-components'
import { device } from '../../styles/variables'

export const S = {};

S.Questions = styled.div`
    display: grid;
    grid-gap: 1rem;
`
S.Item = styled.div`
    box-sizing:border-box;
`

S.Faq = styled.section`
    padding: 1rem;

    h1,
    h4{
        text-align:left;
    }
    h4{
        border-bottom: 1px solid #00C2FF;
        padding-bottom:1rem;
    } 
       
    @media ${device.mobileL}{
        h1,
        h4{
            max-width:400px;
            margin-left: auto;
            margin-right: auto;
        }
        ${S.Item}{
            max-width:400px;
        }
        ${S.Questions}{
            justify-content:center;
            align-content:center;
        }

    }
    @media ${device.tablet}{
        padding:2rem;
        h1,
        h4{
            max-width:900px;
            text-align:center;
        }        
        ${S.Questions}{
            justify-content:center;
            align-content:center;
            grid-template-columns: repeat(3, auto);
        }
        ${S.Item}{
            max-width:280px;
        }
    }
    @media ${device.laptop}{
        ${S.Questions}{
            grid-gap:30px;
        }
    }
`