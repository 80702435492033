import styled from 'styled-components'
import {device} from '../../styles/variables'

export const S = {};

S.Directions = styled.section`
    box-sizing: border-box;
    padding: 1rem;
    background-color:#F7F7F7;
    border: 2px solid white;
`

S.Img = styled.img`
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    border: 2px solid white;
`
S.Contact = styled.ul`
    padding: 0;
    list-style-type: none;
    li{
        display: flex;
        align-items: center;
        justify-content: start;
        color:#00C2FF;
    }
    img{
        width:30px;
        height:40px;
    }
    a{
        color: #00C2FF;
        padding-left: 1rem;
    }
    div{
        padding-left: 1rem;
    }
`

S.Location = styled.div`
    display: grid;
    grid-gap: 1rem;
    justify-content: center;
    align-content: center;
    background-color: #f1f1f1;
    padding: 1rem;
    
    h1{
        border-bottom: 1px solid #00C2FF;
        padding-bottom: 10px;
    }
   @media ${device.tablet}{
       grid-template-columns: repeat(2, auto);
       grid-gap: 1rem;
       padding: 2rem;
   }
   @media ${device.laptop}{
       grid-gap: 100px;
   }
`