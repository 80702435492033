import React from "react"
import Layout from '../layout'
import SEO from '../components/seo'
import { G } from '../styles/components' 
import Faq from '../components/faq'
import Location from '../components/location'

export default () => (
    <Layout>
        <SEO title='Contact' />
        <G.Title>HOW TO FIND US</G.Title>
        <Location />
        <Faq />

    </Layout>
)