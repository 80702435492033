import React from 'react'
import { S } from './style'

export default () => {
    return(
        <S.Faq>
            <h1>FREQUENTLY ASKED QUESTIONS</h1>
            <h4>These are some of the questions we get asked most often. If you have a specific question, feel free to give us a call or send an email.</h4>
            <S.Questions>
                <S.Item>
                    <h3>WHY PDR?</h3>
                    <p>As long as PDR can be performed, its a fraction of the cost compared to the body shop. Preserves the integrity of the original paint on the vehicle. Repair work can usually be performed in the same day, excluding hail.</p>
                </S.Item>
                <S.Item>
                    <h3>WILL THE DENTS RETURN?</h3>
                    <p>No, once the repair work has been performed, the body will remain in its repaired condition.</p>
                </S.Item>
                <S.Item>
                    <h3>DO YOU FIX SCRATCHES?</h3>
                    <p>We only repair the dents without effecting the paint. A detail shop or vendors that specialize in painting small portions of a car (bumpers, spot blending, etc)</p>
                </S.Item>
            </S.Questions>
        </S.Faq>
    )
}