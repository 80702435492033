import React from 'react'
import map from '../../images/map.png'
import { S } from './style'
import location from '../../images/location.svg'
import phone from '../../images/phone.svg'
import email from '../../images/email.svg'
import time from '../../images/time.svg'

export default () => {
    return(
        <S.Location>
            <S.Img src={map}/>
            <S.Directions>
                <h1>Directions / Contact Info</h1>
                <ul>
                    <li>Head north on I-10 W.</li>
                    <li>Take exit 543 and merge onto Frontage Rd.</li>
                    <li>Turn left onto Scenic Loop Rd.</li>
                    <li>Travel for 0.3 mi.</li>
                    <li>Destination will be on the left.</li>
                </ul>
                <S.Contact>
                    <li>
                        <img alt="Map to Andy's Dent Works" src={location}/>
                        <a href='https://www.google.com/maps/dir//29.7499313,-98.7052394/@29.7498133,-98.7061012,402m/data=!3m1!1e3!4m2!4m1!3e0'>31445 Frontage Rd, Boerne, TX 78006</a>
                    </li>
                    <li>
                        <img alt="Email" src={email}/>
                        <a href='mailto:andydents@gmail.com' >andydents@gmail.com</a>
                    </li>
                    <li>
                        <img alt="Phone" src={phone}/>
                        <a href='tel:2104542719'>210 - 454 - 2719</a>
                    </li>
                    <li>
                        <img alt="Hours" src={time}/>
                        <div>Monday - Saturday 9 am - 5 pm</div>
                    </li>
                </S.Contact>
            </S.Directions>
        </S.Location>
    )
}